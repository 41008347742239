<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Time Price">
            <validation-observer ref="updateTimePriceValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Time"
                      label-for="time"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="time"
                        rules="required"
                      >
                        <b-form-select
                          id="time"
                          v-model="form.time"
                          :options="timeOptions"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Price"
                      label-for="price"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        name="price"
                        rules="required"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            GBP
                          </b-input-group-prepend>
                          <b-form-input
                            id="price"
                            v-model="form.price"
                            type="number"
                            placeholder="50"
                          />
                        </b-input-group>
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const businessITSupportModule = createNamespacedHelpers('businessITSupport')

export default {
  data() {
    return {
      loader: false,
      form: {
        time: null,
        price: '',
      },
      timeOptions: [
        { value: null, text: 'Please select one option', disabled: true },
        { value: '30 min', text: '30 min' },
        { value: '1 Hour', text: '1 Hour' },
        { value: '1.5 Hour', text: '1.5 Hour' },
        { value: '2 Hour', text: '2 Hour' },
        { value: '2.5 Hour', text: '2.5 Hour' },
        { value: '3 Hour', text: '3 Hour' },
        { value: '3.5 Hour', text: '3.5 Hour' },
        { value: '4 Hour', text: '4 Hour' },
        { value: '15-30 min', text: '15-30 min' },
        { value: '30-60 min', text: '30-60 min' },
        { value: '1-2 Hour', text: '1-2 Hour' },
        { value: '2-3 Hour', text: '2-3 Hour' },
        { value: '1-3 Hour', text: '1-3 Hour' },
        { value: '2-4 Hour', text: '2-4 Hour' },
        { value: '3-4 Hour', text: '3-4 Hour' },
      ],
    }
  },
  async mounted() {
    this.loader = true
    await this.fetchTimePrice()
    this.loader = false
  },
  methods: {
    ...businessITSupportModule.mapActions(['FETCH_SINGLE_BS_TIME_PRICE', 'UPDATE_BS_TIME_PRICE']),

    async fetchTimePrice() {
      try {
        this.loader = true
        const resp = await this.FETCH_SINGLE_BS_TIME_PRICE(this.$route.params.id)
        this.form.time = resp.time
        this.form.price = resp.price
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updateTimePriceValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const resp = await this.UPDATE_BS_TIME_PRICE({ id: this.$route.params.id, data: this.form })
            if (resp) {
              this.fetchTimePrice()
              this.$nextTick(() => {
                this.$refs.updateTimePriceValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Time Price Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The time price has been updated successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
